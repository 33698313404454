import { Form, Input, message } from "antd";
import React from "react";
import CustomButton from "./Button/custom-button";
import { CopyOutlined } from "@ant-design/icons";
import hexToRGBA from "../helper/hexToRbga";

const webhook_url = "https://api.gigsclass.id/v1/xendits/webhook";

const FormXendit = ({
  handleSubmit,
  setTutorScrtKey,
  setTutorCopyPaste,
  setTutorWebhookToken,
  setSelectedPayment,
}) => {
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div className="w-full md:w-[568px]">
      <p>
        Enter your Xendit secret key and paste the Gigs webhook URL into the
        Xendit webhook URL field.
      </p>

      <Form onFinish={handleSubmit} layout="vertical">
        <Form.Item label="Secret Key" name="xendit_secret_key">
          <Input placeholder="xnd_xxxxxx" className="h-10" />
        </Form.Item>

        <Form.Item>
          <span
            className="text-black underline cursor-pointer"
            onClick={() => setTutorScrtKey(true)}
          >
            How to generate Xendit secret key?
          </span>
        </Form.Item>

        <Form.Item
          label={
            <div className="grid">
              <span>Webhook URL</span>
              <span>Paste this URL to Xendit webhook URL</span>
            </div>
          }
        >
          <Input
            readOnly
            value={webhook_url}
            className="h-10"
            suffix={
              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => copyToClipboard(webhook_url)}
              >
                <CopyOutlined />
                <span>copy</span>
              </div>
            }
          />
        </Form.Item>

        <Form.Item>
          <span
            className="text-black underline cursor-pointer"
            onClick={() => setTutorCopyPaste(true)}
          >
            How to copy and paste Webhook URL?
          </span>
        </Form.Item>

        <Form.Item label="Webhook verification token" name="xendit_webhook_key">
          <Input placeholder="Paste token here" className="h-10" />
        </Form.Item>

        <Form.Item>
          <span
            className="text-black underline cursor-pointer"
            onClick={() => setTutorWebhookToken(true)}
          >
            How to get webhook verification token?
          </span>
        </Form.Item>

        <div
          className="p-2 md:w-[568px] mb-8"
          style={{
            background: hexToRGBA("#FFCD0033", 0.2),
            border: `1px solid ${hexToRGBA("#FFCD0033", 0.3)}`,
          }}
        >
          <b>Important Notes:</b>
          <ol>
            <li>
              Ensure that the selected mode is "Live" and not "Test Mode".{" "}
            </li>
            <li>
              The feature used by Gigs in Xendit is "Payment Links" (as it
              appears on the Xendit dashboard), also known as "Create Invoice"
              (as referred to in the Xendit API).
            </li>
            <li>
              Each studio uses its own Xendit account, so all transactions go
              directly to your account. Studios handle their own Xendit fees.
              Currently, Gigs doesn’t charge any admin fees.
            </li>
          </ol>
        </div>

        <Form.Item>
          <div className="flex justify-between">
            <CustomButton
              buttonType="button"
              type="secondary"
              title="Cancel"
              onClick={() => setSelectedPayment("")}
            />

            <CustomButton
              buttonType="submit"
              type="primary"
              title="Save"
              className="border-0"
              // isLoading={isLoading}
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormXendit;
