import { Image, Modal } from "antd";
import React from "react";

import xenditwebhooktoken1 from "../../assets/images/webhook-token1.svg";
import xenditwebhooktoken2 from "../../assets/images/webhook-token2.svg";
import xenditwebhooktoken3 from "../../assets/images/webhook-token3.svg";
import xenditwebhooktoken4 from "../../assets/images/webhook-token4.svg";

const ModalTutorWebhookToken = ({
  tutorWebhookToken,
  setTutorWebhookToken,
}) => {
  return (
    <Modal
      centered
      open={tutorWebhookToken}
      closable={false}
      footer={false}
      width={745}
      onCancel={() => setTutorWebhookToken(false)}
    >
      <div className="grid gap-4 justify-items-center">
        <h4 className="mb-6">How to generate Xendit secret key</h4>
        <p className="mb-0">
          1. Go to <b>Settings</b> {">"} <b>API Keys</b>.
        </p>
        <Image src={xenditwebhooktoken1} alt="" />
        <p className="mb-0">
          2. In the <b>Webhooks</b> section, click{" "}
          <b>View Webhook Verification Token</b> .
        </p>
        <Image src={xenditwebhooktoken2} alt="" />
        <p className="mb-0">
          3. A pop-up will appear. enter your account password.
        </p>
        <Image src={xenditwebhooktoken3} alt="" />
        <p className="mb-0">
          4. Copy the <b>Webhook Verification Token</b>.
        </p>
        <Image src={xenditwebhooktoken4} alt="" />
      </div>
    </Modal>
  );
};

export default ModalTutorWebhookToken;
