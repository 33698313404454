import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import "./../classes.styles.scss";

import { Row, Col, Form } from "react-bootstrap";
import Button from "../../../../../components/Button/button.component";
import TextInput from "../../../../../components/TextInput/text-input.component";
import iconRemove from "../../../../../assets/images/Icon feather-delete.png";

import { setclassNameSetup } from "../../../../../redux/owner/owner.action";
import { getEligiblePlans } from "../../../../../services/classes";
import { toast } from "react-toastify";
import { Modal, Skeleton } from "antd";
import {
  Button as AntButton,
  Select as AntSelect,
  Input as AntInput,
} from "antd";
import AddLocations from "../../../../Owner/add-locations-component";
import { getLocation } from "../../../../../redux/location/location.action";
import hexToRGBA from "../../../../../helper/hexToRbga";
import { capitalize } from "../../../../../helper/truncateString";

const ClassNameSetup = ({
  isCreating,
  isOnboarding,
  nextStepSchedule,
  setStepSchedule,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies] = useCookies(["user"]);

  const [className, setClassName] = useState("");
  const [isOnline, setIsOnline] = useState(0);
  const [room, setRoom] = useState("");
  const [slots, setSlots] = useState("");
  const [classlink, setClassLink] = useState("");
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [notes, setNotes] = useState("");
  const [price, setPrice] = useState("");
  const [tax, setTax] = useState("11");
  const [credits, setCredits] = useState("");
  const [selectedPlans, setSelectedPlans] = useState("all_plans");
  const [eligiblePlans, setEligiblePlans] = useState([]);
  const [plansData, setPlansData] = useState([]);
  const [planId, setPlanId] = useState("");
  const [directPayment, setDirectPayment] = useState(false);
  const [validUrl, setValidUrl] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addCategory, setAddCategory] = useState("");
  const [classCategories, setClassCategories] = useState([]);
  const [aboutClass, setAboutClass] = useState("");
  const [isAddLocation, setIsAddLocation] = useState(false);
  const [locationId, setLocationId] = useState([]);

  const { isRegisterStudent, class_categories } = useSelector(
    (state) => state.studio
  );

  const {
    addClass,
    class_detail,
    isLoadingClassDetail,
    onboarding_status,
    addLocationStatus,
  } = useSelector((state) => state.owner);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );
  const { location } = useSelector((state) => state.location);

  const colorTheme = studio_detail?.styles?.header_color;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const plans = [
    { label: "Choose Your Plans", value: "", selected: true },
    ...eligiblePlans,
  ];

  useEffect(() => {
    const setClassDetails = (details) => {
      setClassName(details?.class_name ?? "");
      setIsOnline(details?.is_online ?? "");
      setRoom(details?.room ?? "");
      setSlots(details?.slots ?? "");
      setDirectPayment(
        details?.direct_payment_allowed === 1 ? true : false ?? ""
      );
      setNotes(details?.notes ?? "");
      setPrice(details?.class_price ?? "");
      setTax(details?.class_tax ?? "");
      setCredits(details?.credits ?? "");
      if (details?.is_online === 1) {
        setClassLink(details?.class_link ?? "");
        setAdditionalInformation(details?.additional_information ?? "");
      }
      setSelectedPlans(
        details?.all_plans_allowed ? "all_plans" : "specific_plans" ?? ""
      );
      setPlansData(details?.plans ?? []);
      setClassCategories(
        details?.class_categories?.map((item) => ({
          label: capitalize(item.name || item.label || " "),
          value: item._id ?? item.value,
        })) ?? []
      );
      setAboutClass(details?.about_the_class ?? "");
      setLocationId(details?.location_ids?.map((item) => item.id));
    };

    if (!isCreating) {
      setClassDetails(class_detail);
    } else {
      setClassDetails(addClass);
    }

    handleGetEligiblePlans();
  }, [isCreating, class_detail, addClass]);

  useEffect(() => {
    dispatch(getLocation("?limit=0", user_token, studio_id));
  }, [addLocationStatus]);

  const handleSubmit = (e) => {
    if (isCreating) e.preventDefault();

    let planIds = "";

    plansData?.map((e, idx) => {
      if (idx === 0) {
        planIds = e.id;
      } else {
        planIds = planIds?.concat(",", e.id);
      }
    });

    let params = {
      class_name: className,
      credits: credits,
      is_online: isOnline,
      room: room,
      slots: slots,
      class_link: classlink,
      additional_information: additionalInformation,
      notes: notes,
      direct_payment_allowed: directPayment ? 1 : 0,
      all_plans_allowed: selectedPlans === "all_plans" ? 1 : 0,
      plan_ids: planIds,
      plans_data: plansData,
      class_categories: classCategories,
      about_the_class: aboutClass,
      location_ids: locationId,
    };

    if (directPayment) {
      params.class_price = price;
      params.class_tax = tax;
    }

    dispatch(setclassNameSetup(params));
    isCreating ? nextStepSchedule() : setStepSchedule(3);
  };

  const handleGetEligiblePlans = async () => {
    try {
      const res = await getEligiblePlans(
        user_token,
        studio_id,
        class_detail?.id ?? 0
      );

      setEligiblePlans(res.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddIdPlan = () => {
    if (planId === "") {
      toast.error("Please Choose a Plan");
      return;
    } else if (plansData.length > 0) {
      if (plansData?.find((x) => x.id === planId)) {
        toast.error("Plan already exist!");
        return;
      }
    }

    let plans = eligiblePlans?.find((e) => e?.id === planId);
    let params = { id: plans?.id, plan_name: plans.plan_name };

    setPlansData([...plansData, params]);
  };

  const removePlan = (idx) => {
    let deletedItem = plansData[idx];
    const afterDelete = plansData.filter((e) => e !== deletedItem);
    setPlansData(afterDelete);
  };

  const urlPatternValidation = (URL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(URL);
  };

  const changeUrl = (value) => {
    const isTrueVal = !value || urlPatternValidation(value);
    setValidUrl(isTrueVal);
    setClassLink(value);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleAddCategory = () => {
    setClassCategories([
      ...classCategories,
      { label: addCategory, value: addCategory },
    ]);
    setAddCategory("");
    setIsModalOpen(false);
  };

  const capitalize = (str) =>
    str
      .split(" ") // Split the string into words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" ");

  return (
    <Col lg={6}>
      {isAddLocation ? (
        <AddLocations
          withoutSkipButton
          isAddLocation={isAddLocation}
          colorTheme={colorTheme}
          setIsAddLocation={setIsAddLocation}
        />
      ) : isLoadingClassDetail ? (
        <Skeleton active />
      ) : (
        <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <div className="" style={{ paddingTop: 20 }}>
            <Form.Group>
              <Form.Label className="custom-form-label">Class Type</Form.Label>
              <AntSelect
                className="w-full rounded-sm custom-select-ant"
                size="large"
                placeholder="Select Class Type"
                onChange={(e) => setIsOnline(e)}
                defaultValue={0}
                value={isOnline}
                options={[
                  {
                    label: "Online",
                    value: 1,
                  },
                  {
                    label: "Onsite",
                    value: 0,
                  },
                ]}
              />
            </Form.Group>

            <Form.Group>
              <TextInput
                required
                label="Class Name"
                placeholder="Healty and young yoga"
                value={className}
                onChange={(e) => setClassName(e.target.value)}
              />
            </Form.Group>

            {isOnline ? (
              <>
                <Form.Group>
                  <TextInput
                    required
                    label="Available Slots"
                    placeholder="0"
                    value={slots}
                    onChange={(event) => {
                      setSlots(event.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <TextInput
                    required
                    label="Class Link"
                    placeholder="https://example.com"
                    value={classlink}
                    onChange={(event) => {
                      setClassLink(event.target.value);
                    }}
                  />
                </Form.Group>
              </>
            ) : (
              <></>
            )}

            <Form.Group>
              <Form.Label className="custom-form-label">
                Class Category
              </Form.Label>

              <AntSelect
                labelInValue
                mode="multiple"
                placeholder="Choose Category"
                className="w-full rounded-sm capitalize"
                size="large"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <div
                      onClick={showModal}
                      className="w-full flex px-[15px] py-2 items-center text-neutral-800 text-sm font-semibold cursor-pointer hover:bg-slate-50"
                    >
                      Add a Category..
                    </div>
                  </>
                )}
                options={[
                  ...classCategories,
                  ...class_categories
                    .filter(
                      (cat) => !classCategories.some((c) => c.value === cat._id)
                    )
                    .map((cat) => ({
                      label: capitalize(cat.name || " "),
                      value: cat._id,
                    })),
                ]}
                value={classCategories}
                onChange={(value) => {
                  setClassCategories(value);
                }}
              />
            </Form.Group>
            {/* <div className="my-3">
              {Array.from({ length: 5 }).map((_, idx) => (
                <Tag
                  key={idx}
                  className="p-2 m-1"
                  closable
                  style={{
                    backgroundColor: colorTheme,
                    color: "#fff",
                  }}
                >
                  <span style={{ fontSize: "14px" }}>Health & Wellness</span>
                </Tag>
              ))}
            </div> */}

            <Form.Group className="mt-4">
              <TextInput
                required
                type="number"
                label="Credits"
                placeholder="Input credits..."
                value={credits}
                onChange={(e) => setCredits(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="my-4">
              <Form.Label className="custom-form-label" htmlFor="about">
                About the class
              </Form.Label>
              <AntInput.TextArea
                id="about"
                required
                placeholder="Type about the class here..."
                rows={4}
                value={aboutClass}
                onChange={(e) => setAboutClass(e.target.value)}
              />
            </Form.Group>

            {/* {!isOnline && (
              <div className="flex flex-row w-full items-center justify-center my-3">
                <AntButton
                  type="default"
                  className="w-1/2 rounded-sm"
                  style={{
                    color: colorTheme,
                    border: `1px solid ${colorTheme}`,
                  }}
                  size="large"
                  onClick={() => setIsLocation(true)}
                >
                  Add Locations
                </AntButton>
              </div>
            )} */}

            <Form.Group className="mb-4">
              <Form.Label className="custom-form-label">Location</Form.Label>

              <div
                className="py-2 px-4 mb-2"
                style={{
                  backgroundColor: hexToRGBA(colorTheme || "#E26851", 0.1),
                  border: `1px solid ${hexToRGBA(colorTheme, 0.2)}`,
                }}
              >
                <span className="font-medium">
                  Select a location to set as your timezone reference before
                  enrolling.
                </span>
              </div>

              <AntSelect
                size="large"
                mode="multiple"
                placeholder="Jakarta"
                options={location.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
                value={locationId}
                onChange={(e) => {
                  setLocationId(e);
                }}
                className="w-full rounded-sm"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <div
                      onClick={() => setIsAddLocation(true)}
                      className="w-full flex px-[15px] py-2 items-center text-neutral-800 text-sm font-semibold cursor-pointer hover:bg-slate-50"
                    >
                      Add a Location
                    </div>
                  </>
                )}
              />
            </Form.Group>

            {!isOnline ? (
              <Form.Group>
                <TextInput
                  required
                  label="Room"
                  placeholder="2A Room"
                  value={room}
                  onChange={(event) => {
                    setRoom(event.target.value);
                  }}
                />
              </Form.Group>
            ) : (
              <></>
            )}

            {!isOnline ? (
              <Form.Group>
                <TextInput
                  required
                  label="Available Slots"
                  placeholder="0"
                  value={slots}
                  onChange={(event) => {
                    setSlots(event.target.value);
                  }}
                />
              </Form.Group>
            ) : (
              <></>
            )}

            {isOnline ? (
              <Form.Group>
                <Form.Label className="custom-form-label">
                  Additional Information
                </Form.Label>
                <AntInput.TextArea
                  required
                  placeholder="Enter all meeting details: ID, password, and any additional notes (e.g., Meeting ID: 123-456-789, Password: 4Fz8PQ, Notes: Agenda or topics)"
                  className="w-full"
                  rows={4}
                  value={additionalInformation}
                  onChange={(event) => {
                    setAdditionalInformation(event.target.value);
                  }}
                />
              </Form.Group>
            ) : (
              <></>
            )}

            <Form.Group>
              <TextInput
                required
                label="Notes"
                placeholder="Type notes here..."
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Form.Group>

            {/* <div className="w-full flex flex-row items-center justify-center">
              <Button
                type={"outline-secondary"}
                className="w-1/2 "
                title={"Add notes"}
                style={{
                  color: colorTheme,
                  borderColor: colorTheme,
                }}
              />
            </div> */}

            {/* <div className="my-3">
              {Array.from({ length: 5 }).map((_, idx) => (
                <Tag
                  key={idx}
                  className="p-2 m-1 w-full flex flex-row justify-between"
                  closable
                  style={{
                    backgroundColor: hexToRGBA(
                      colorTheme,
                      0.1
                    ),
                    color: colorTheme,
                  }}
                >
                  <span style={{ fontSize: "14px" }}>
                    Lorem ipsum dolor sit amet
                  </span>
                </Tag>
              ))}
            </div> */}
          </div>

          <div className="my-3">
            <span className="custom-form-label mb-2">Eligible Plans</span>
            <Form.Group style={{ marginBottom: 10 }} className="custom-radio">
              <Form.Check
                type="radio"
                label="All Plans"
                value="all_plans"
                name="all plans"
                id="1"
                onChange={(e) => setSelectedPlans(e.target.value)}
                checked={selectedPlans === "all_plans"}
                style={{
                  "--active-color": colorTheme,
                }}
              />
              <span className="fs-10 ms-4">
                This class will have all plan options available
              </span>
              <Form.Check
                className="mt-3 "
                type="radio"
                label="Specific Plans"
                value="specific_plans"
                name="specific plans"
                id="2"
                onChange={(e) => setSelectedPlans(e.target.value)}
                checked={selectedPlans === "specific_plans"}
                style={{
                  "--active-color": colorTheme,
                }}
              />
              {selectedPlans === "specific_plans" && (
                <>
                  <div
                    style={{ paddingLeft: "25px" }}
                    className={`form-group mt-3`}
                  >
                    <label className="custom-form-label mb-2">
                      Select Plans
                    </label>
                    <AntSelect
                      size="large"
                      placeholder="Choose Plans"
                      onChange={(e) => setPlanId(e)}
                      options={plans.map((element) => ({
                        label: element.label ?? element.plan_name,
                        value: element.id,
                      }))}
                    />
                  </div>

                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      type="primary"
                      title="Add"
                      onClick={() => handleAddIdPlan()}
                      style={{
                        backgroundColor: colorTheme,
                      }}
                    />
                  </div>
                </>
              )}
              <div>
                <div className="ps-4 mt-3">
                  {selectedPlans === "specific_plans" && plansData?.length > 0
                    ? plansData?.map((element, idx) => (
                        <div
                          className="d-flex justify-content-between border-bottom border-1 py-2"
                          key={idx + 1}
                        >
                          <div className="d-flex gap-3">
                            <span>{`#${idx + 1}`}</span>
                            <span>{element?.plan_name}</span>
                          </div>
                          <div
                            onClick={() => {
                              removePlan(idx);
                            }}
                            className="pointer custom-hover"
                          >
                            <img alt="" src={iconRemove} />
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
              <Row className="mt-3">
                <Form.Group style={{ marginBottom: 10 }}>
                  <Form.Check
                    type="checkbox"
                    label="Direct Payment"
                    name="direct payment"
                    id="3"
                    checked={directPayment}
                    onChange={(event) => {
                      setDirectPayment(event.target.checked);
                    }}
                    style={{
                      "--active-color": colorTheme,
                    }}
                  />
                  <span className="fs-10 ms-4">
                    Students can use direct payment
                  </span>
                </Form.Group>
                {directPayment ? (
                  <>
                    <Col>
                      <Form.Group
                        style={{ marginBottom: 10, marginLeft: "1.5rem" }}
                      >
                        <TextInput
                          required
                          type="price"
                          label="Class Price"
                          placeholder="Input Price..."
                          value={price}
                          onChange={(event) => {
                            setPrice(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        style={{ marginBottom: 10, marginLeft: "1.5rem" }}
                      >
                        <TextInput
                          required
                          type="number"
                          label="Tax (%)"
                          placeholder="Input Tax..."
                          value={tax}
                          onChange={(event) => {
                            setTax(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </>
                ) : null}
              </Row>
            </Form.Group>
          </div>

          <div className="flex flex-col gap-2 w-full items-center">
            <AntButton
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: colorTheme,
              }}
              size="large"
              className={"w-full rounded-sm"}
            >
              Next
            </AntButton>

            <AntButton
              type="default"
              size="large"
              className="w-full rounded-sm"
              onClick={() => {
                if (isOnboarding) {
                  onboarding_status?.plan === false
                    ? history.push({
                        pathname: "/admin/add-plan",
                        state: { isOnboarding: true },
                      })
                    : onboarding_status?.term_and_condition === false
                    ? history.push({
                        pathname: "/admin/setting-terms-and-conditions",
                        state: { isOnboarding: true, step: 2 },
                      })
                    : onboarding_status?.cancellation_setting === false
                    ? history.push({
                        pathname: "/admin/setting-cancellation",
                        state: { isOnboarding: true, step: 2 },
                      })
                    : onboarding_status?.payment_information === false
                    ? history.push({
                        pathname: "/admin/setting-payment-gateway",
                        state: { isOnboarding: true, step: 2 },
                      })
                    : history.goBack();
                } else {
                  history.goBack();
                }
              }}
              style={{
                borderColor: colorTheme,
                color: colorTheme,
              }}
            >
              {isOnboarding ? "Skip for Now" : "Cancel"}
            </AntButton>
          </div>
        </Form>
      )}

      <Modal
        open={isModalOpen}
        centered
        closable={false}
        footer={null}
        className="rounded-sm"
        onClose={() => setIsModalOpen(false)}
      >
        <div className="w-full flex flex-col items-center gap-3">
          <h5>Fill out the name of the New Category</h5>

          <AntInput
            placeholder="Input Name of Category"
            size={"large"}
            onChange={(event) => {
              setAddCategory(event.target.value);
            }}
          />

          <div className="flex flex-col gap-3 items-center w-full">
            <AntButton
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: colorTheme,
              }}
              size="large"
              className={"w-full p-2 rounded-sm"}
              onClick={handleAddCategory}
            >
              Add Category
            </AntButton>

            <AntButton
              type="text"
              htmlType="button"
              style={{
                color: colorTheme,
              }}
              size="large"
              className={"w-full rounded-sm p-2"}
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </AntButton>
          </div>
        </div>
      </Modal>
    </Col>
  );
};

export default ClassNameSetup;
