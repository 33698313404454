import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

import "./../classes.styles.scss";

import { Col, Row } from "react-bootstrap";
import Button from "../../../../../components/Button/button.component";
import {
  Button as AntButton,
  Avatar,
  Form,
  message,
  Modal,
  Space,
  Tag,
} from "antd";

import {
  addSchedule,
  addDuration,
} from "../../../../../redux/owner/owner.action";
import ScheduleFormlist from "../../../../../components/ScheduleFormlist";
import DatePickerCustom from "../../../../../components/DatePickerCustom";
import dayjs from "dayjs";
import hexToRGBA from "../../../../../helper/hexToRbga";
import {
  sendGetRequest,
  sendPostMultipartRequest,
  sendPostRequest,
} from "../../../../../services/students";
import { GenerateErrorMessage } from "../../../../../services/generate-error-message";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const ClassDurationSetup = ({
  prevStepSchedule,
  nextStepSchedule,
  id,
  setParams,
}) => {
  const [form] = Form.useForm();

  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);

  const [repeatAmount, setRepeatAmount] = useState(1);
  const [repeatDuration, setRepeatDuration] = useState("weeks");
  const [stopRepeating, setstopRepeating] = useState("after");
  const [schedules, setSchedules] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [payload, setPayload] = useState({});
  const [isNeverEnds, setIsNeverEnds] = useState(false);
  const [isSimulate, setIsSimulate] = useState(false);

  const { addClass, class_detail } = useSelector((state) => state.owner);
  const { user, studio_detail } = useSelector((state) => state.user);

  const isCreating = window.location.pathname.includes("add");
  const colorTheme = studio_detail?.styles?.header_color;
  const user_token = user?.token || cookies.user?.token;
  const studio_id = user?.studio_id || cookies.user?.studio_id;

  // useEffect(() => {
  //   if (!isCreating) {
  //     if (schedules.length > 0) {
  //       // Function to filter and normalize schedules
  //       const normalizeSchedules = (schedules) => {
  //         return schedules
  //           .filter(
  //             ({ start_time, end_time }) =>
  //               dayjs(start_time, "HH:mm", true).isValid() &&
  //               dayjs(end_time, "HH:mm", true).isValid()
  //           )
  //           .map(({ day, start_time, end_time }) => ({
  //             day: day.toLowerCase(),
  //             start_time,
  //             end_time,
  //           }));
  //       };

  //       // Normalize both sets of schedules
  //       const validApiSchedules = normalizeSchedules(class_detail?.schedules);
  //       const validSubmitSchedules = normalizeSchedules(schedules);

  //       // Merge schedules, allowing duplicates for the same day
  //       const mergedSchedules = [...validApiSchedules, ...validSubmitSchedules];
  //       console.log({ mergedSchedules });

  //       setSchedules(mergedSchedules);
  //     }
  //   }
  // }, [isCreating, class_detail, schedules]);

  useEffect(() => {
    if (isReady && schedules.length > 0 && Object.entries(payload).length > 0) {
      dispatch(addSchedule(schedules));
      dispatch(addDuration(payload));
      nextStepSchedule();
    }
  }, [dispatch, isReady, nextStepSchedule, payload, schedules]);

  useEffect(() => {
    const getupdate = async () => {
      try {
        const { data } = await sendGetRequest(
          user_token,
          studio_id,
          `/classes/${id}/schedules`
        );
        console.log({ data });
      } catch (error) {}
    };
    getupdate();
  }, []);

  const generateInitialValues = (days, isCreating, addClass, classDetail) => {
    return days.reduce((acc, day) => {
      const schedules = isCreating
        ? addClass.schedule
        : classDetail?.schedules || [];

      const daySchedules = schedules.filter(
        (schedule) => schedule.day.toLowerCase() === day.toLowerCase()
      );

      const validSchedules = daySchedules.filter((schedule) =>
        ["start_time", "end_time"].every((timeKey) =>
          dayjs(schedule[timeKey], "HH:mm", true).isValid()
        )
      );

      acc[day] = validSchedules.length
        ? validSchedules.map(({ start_time, end_time }) => ({
            startTime: start_time,
            endTime: end_time,
          }))
        : [{ startTime: "", endTime: "" }];
      acc[`${day}Checked`] = validSchedules.length > 0;

      return acc;
    }, {});
  };

  const initialValues = generateInitialValues(
    days,
    isCreating,
    addClass,
    class_detail
  );

  const handleSubmit = async (value) => {
    console.log({ value });

    setIsReady(false);

    const arrSchedule = [];
    const ids = [];

    const isTimeSlotIncluded = (day, startTime, endTime) => {
      const timeSlotId = `${day}${startTime}-${endTime}`;
      return ids.includes(timeSlotId);
    };

    const processDaySchedule = (day, daySchedule) => {
      daySchedule.forEach((schedule) => {
        if (schedule.day === false) {
          console.log(`Skipping schedule for ${day} as day is false.`);
          return;
        }

        const startTime = schedule.timeRange
          ? dayjs(schedule.timeRange.startTime).format("HH:mm")
          : schedule.startTime;

        const endTime = schedule.timeRange
          ? dayjs(schedule.timeRange.endTime).format("HH:mm")
          : schedule.endTime;
        const dayLowerCase = day;

        console.log(`Processing ${day}:`, { startTime, endTime });

        if (
          startTime &&
          endTime &&
          dayjs(startTime, "HH:mm", true).isValid() &&
          dayjs(endTime, "HH:mm", true).isValid()
        ) {
          if (isTimeSlotIncluded(dayLowerCase, startTime, endTime)) {
            setIsReady(false);
            toast.error("Please Select Different Time!");
          } else {
            setIsReady(true);
            const timeSlotId = `${dayLowerCase}${startTime}-${endTime}`;
            ids.push(timeSlotId);
            arrSchedule.push({
              day: dayLowerCase,
              start_time: startTime,
              end_time: endTime,
            });
          }
        }
      });
    };

    days.forEach((day) => {
      if (value[day] && value[day].length > 0) {
        processDaySchedule(day, value[day]);
      }
    });

    console.log("ready to schedule", arrSchedule);

    const formatDate = (date) => {
      if (!date) return "";
      const { year, month, day } = date;
      return `${year}-${month}-${day}`;
    };

    const finalStartDate = formatDate(value.start_date);
    const finalEndDate = isNeverEnds
      ? dayjs(formatDate(value.start_date)).add(5, "year").format("YYYY-MM-DD")
      : formatDate(value.end_date);

    const params = {
      id,
      start_date: finalStartDate,
      end_date: finalEndDate,
      repeat_every: repeatAmount,
      repeat_duration: repeatDuration,
    };

    if (arrSchedule.length > 0) {
      // Function to filter and normalize schedules
      const normalizeSchedules = (parSchedules) => {
        return parSchedules
          ?.filter(
            ({ start_time, end_time }) =>
              dayjs(start_time, "HH:mm", true).isValid() &&
              dayjs(end_time, "HH:mm", true).isValid()
          )
          .map(({ day, start_time, end_time }) => ({
            day: day,
            start_time,
            end_time,
          }));
      };
      console.log("Normalize input:", arrSchedule);
      // Normalize both sets of schedules
      const validApiSchedules = normalizeSchedules(
        class_detail?.schedules || []
      );
      const validSubmitSchedules = normalizeSchedules(arrSchedule);

      const mergedSchedules = validApiSchedules.map((apiSchedule) => {
        const matchingSchedule = validSubmitSchedules.find(
          (submitSchedule) => submitSchedule.day === apiSchedule.day
        );
        return matchingSchedule || apiSchedule;
      });

      // Add any new schedules not present in the original
      validSubmitSchedules.forEach((submitSchedule) => {
        if (
          !mergedSchedules.some(
            (mergedSchedule) => mergedSchedule.day === submitSchedule.day
          )
        ) {
          mergedSchedules.push(submitSchedule);
        }
      });

      console.log({ mergedSchedules });
      setSchedules(validSubmitSchedules);
      setPayload(params);
      try {
        await sendPostMultipartRequest(
          user_token,
          studio_id,
          "/classes/simulate",
          {
            ...params,
            schedule: validSubmitSchedules,
          }
        );
      } catch (error) {
        message.error(<GenerateErrorMessage error={error} />);
      }
    }
  };

  const statusColors = {
    upcoming: "#0296CF",
    registered: "#34A853",
    waiting_list: "#F9B032",
    pending: "#8A9AB3",
    Unattended: "#EF4136",
    Attended: "#34A853",
    cancelled: "#EF4136",
  };

  const color = statusColors["pending"];

  return (
    <Col lg={7}>
      <Form form={form} initialValues={initialValues} onFinish={handleSubmit}>
        <h6 className="text-slate-900 text-lg font-bold my-[25px]">Period</h6>
        <Form.Item label="Start date" name="start_date">
          <DatePickerCustom currentDate={class_detail?.class_start_date} />
        </Form.Item>

        <Form.Item label="End date" name="end_date">
          <DatePickerCustom
            endDate
            isNeverEnds={isNeverEnds}
            setIsNeverEnds={setIsNeverEnds}
            currentDate={
              class_detail?.class_end_date !== ""
                ? class_detail?.class_end_date
                : class_detail?.class_start_date
            }
          />
        </Form.Item>

        <h6 className="text-slate-900 text-lg font-bold my-[25px]">
          Schedules
        </h6>
        {days.map((day) => (
          <ScheduleFormlist
            key={day}
            name={day}
            colorTheme={colorTheme}
            form={form}
          />
        ))}

        <div className="w-full flex flex-col gap-3">
          <Button
            buttonType="submit"
            type="primary"
            title="Next"
            style={{ width: "100%", backgroundColor: colorTheme }}
          />

          <AntButton
            type="text"
            htmlType="reset"
            style={{
              color: colorTheme,
            }}
            size="large"
            className="w-full rounded-sm"
            onClick={() => {
              prevStepSchedule();
            }}
          >
            Back
          </AntButton>
        </div>
      </Form>

      <Modal centered open={isSimulate} closable={false} footer={false}>
        <h5 className="font-bold">You have 5 appointment(s) to cancel</h5>
        <div
          className="px-4 py-2 my-8"
          style={{
            background: hexToRGBA("#FFCD0033", 0.2),
            border: `1px solid ${hexToRGBA("#FFCD0033", 0.3)}`,
          }}
        >
          By canceling, the order will be immediately canceled, and this action
          cannot be undone
        </div>

        <div
          style={{
            border: `1px solid ${hexToRGBA("#0D47A147", 0.3)}`,
          }}
          className="flex justify-between mb-8"
        >
          <div className="flex gap-4">
            <div
              className="grid place-items-center"
              style={{
                background: colorTheme,
                height: 165,
                width: 60,
              }}
            >
              <div className="grid text-center text-white">
                <span>Wed</span>
                <span className="text-2xl">24</span>
                <span>Jan</span>
              </div>
            </div>

            <div className="">
              <p className="text-xl font-medium my-1">08:00 - 09:00</p>
              <div className="flex gap-4">
                <span className="text-[#637381] w-14">Student</span>

                <Space>
                  <Avatar size={24} />
                  <span>Jane Doe</span>
                </Space>
              </div>
              <div className="flex gap-4 my-2">
                <span className="text-[#637381] w-14">Location</span>
                <span>Jakarta</span>
              </div>
              <div className="flex gap-4">
                <span className="text-[#637381] w-14">Status</span>

                <Tag color={color}>Pending</Tag>
              </div>
            </div>
          </div>

          <div className="flex items-end mr-4 mb-2">
            <AntButton danger type="primary" className="rounded-none">
              Cancel
            </AntButton>
          </div>
        </div>

        <div className="grid place-content-center gap-3">
          <Button
            buttonType="submit"
            type="primary"
            title="Update Schedules"
            style={{ backgroundColor: colorTheme, width: 207, borderRadius: 0 }}
          />

          <AntButton
            type="text"
            style={{
              color: colorTheme,
            }}
            size="large"
            className="rounded-sm"
            onClick={() => {
              prevStepSchedule();
            }}
          >
            Cancel
          </AntButton>
        </div>
      </Modal>
    </Col>
  );
};

export default ClassDurationSetup;
