import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../components/Button/button.component";

import { useCookies } from "react-cookie";
import { addUpdatePayment } from "../../redux/owner/owner.action";
import { connectToStripe, getBanksCode } from "../../services/payment";
import { ConfigProvider, Form, Input, Radio, Select } from "antd";
import hexToRGBA from "../../helper/hexToRbga";
import oyLogo from "../../assets/images/oy-logo.svg";
import stripeLogo from "../../assets/images/stripe-logo.svg";
import xenditLogo from "../../assets/images/xendit-logo.svg";

import ModalTutorSecretKey from "../../components/Modal/tutor-secret-key";
import ModalTutorCopas from "../../components/Modal/tutor-copas";
import FormXendit from "../../components/form-xendit";
import ModalTutorWebhookToken from "../../components/Modal/tutor-webhook-token";

const paymentGatewayOps = [
  {
    value: "oy",
    logo: oyLogo,
    description: "Recommendation for IDR currency",
  },
  {
    value: "stripe",
    logo: stripeLogo,
    description: "Recommendation for USD currency",
  },
  {
    value: "xendit",
    logo: xenditLogo,
    description: "Recommendation for IDR currency",
  },
];

const SettingPayment = ({ colorTheme }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies] = useCookies(["user"]);
  // const [bankList, setBankList] = useState([]);
  const [paymentCode, setPaymentCode] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [tutorScrtKey, setTutorScrtKey] = useState(false);
  const [tutorCopyPaste, setTutorCopyPaste] = useState(false);
  const [tutorWebhookToken, setTutorWebhookToken] = useState(false);

  const { user } = useSelector((state) => state.user);

  // const list_bank = paymentCode?.length > 0 ? paymentCode : [];
  const user_token = user.token || cookies.user.token;
  const studio_id = user.studio_id || cookies.user.studio_id;

  const subdomain = window.location.host.split(".")[0];

  // const dataBank = [
  //   { label: "Choose Bank Name", value: "", selected: true },
  //   ...list_bank,
  // ];

  // const handleDelete = (idx) => {
  //   let deletedItem = bankList[idx];
  //   let newData = bankList.filter((e) => e !== deletedItem);

  //   setBankList(newData);
  // };

  const handleSubmit = async ({
    recipient_bank_code,
    recipient_account,
    recipient_name,
    xendit_secret_key,
    xendit_webhook_key,
  }) => {
    const params =
      selectedPayment === "oy"
        ? {
            recipient_name,
            recipient_account,
            recipient_bank_code,
            payment_gateway: "oy",
          }
        : {
            xendit_secret_key,
            xendit_webhook_key,
            payment_gateway: "xendit",
          };

    try {
      await addUpdatePayment(params, user_token, studio_id);
      history.push({
        pathname: "/dashboard",
      });
    } catch (error) {}
  };

  const handleGetPaymentCode = async () => {
    try {
      const res = await getBanksCode(user_token, studio_id);
      const optCodeBank = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.code,
        };
      });
      setPaymentCode(optCodeBank);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // dispatch(getListBanks(user_token, studio_id));
    handleGetPaymentCode();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChangePayment = (e) => {
    setSelectedPayment(e.target.value);
  };

  const handleConnectStripe = async () => {
    try {
      dispatch(
        addUpdatePayment(
          { payment_gateway: selectedPayment },
          user_token,
          studio_id
        )
      );

      const { data } = await connectToStripe(
        user_token,
        studio_id,
        process.env.REACT_APP_PROTOCOL +
          subdomain +
          `.${process.env.REACT_APP_HOST}/${
            user?.role === "owner" ? "admin/dashboard" : "dashboard"
          }`
      );
      const url = data.data.url;

      window.location.href = url;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full p-4 min-h-screen flex flex-col items-center">
      <h2 className="font-semibold my-10">Setup payment gateway</h2>
      <div className=" text-black text-[20px] font-semibold my-6">
        Input your bank account to payout
      </div>

      <div
        className="px-4 py-2 md:w-[568px] mb-8"
        style={{
          background: hexToRGBA("#FFCD0033", 0.2),
          border: `1px solid ${hexToRGBA("#FFCD0033", 0.3)}`,
        }}
      >
        <span>
          You can't change the payment gateway after a transaction has been made
          at your studio
        </span>
      </div>

      <div className="flex items-start mb-2">
        <b>Choose Payment Gateway</b>
      </div>

      <div
        className="px-4 py-2 md:w-[568px] mb-4"
        style={{
          background: hexToRGBA("#0D47A1", 0.1),
          border: `1px solid ${hexToRGBA("#0D47A1", 0.2)}`,
        }}
      >
        <span>
          The payment gateway you choose will be used for all transactions in
          your studio
        </span>
      </div>

      <Radio.Group onChange={onChangePayment} value={selectedPayment}>
        {paymentGatewayOps.map((item) => (
          <div
            className="p-4 md:w-[568px] mb-4 flex items-center gap-4"
            style={{
              border: `1px solid ${hexToRGBA("#E0E0E0", 1)}`,
            }}
          >
            <Radio value={item.value}>
              <img src={item.logo} alt="" /> <br />
              <span>{item.description}</span>
            </Radio>
          </div>
        ))}
      </Radio.Group>

      {selectedPayment === "oy" ? (
        <ConfigProvider
          theme={{
            components: {
              Form: {},
            },
          }}
        >
          <Form
            onFinish={handleSubmit}
            layout="vertical"
            className="w-full md:w-[568px]"
          >
            <Form.Item label="Choose Bank Account" name="recipient_bank_code">
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={paymentCode}
                className="h-10"
                placeholder="Choose Bank Name"
              />
            </Form.Item>
            <Form.Item label="Account Holder Name" name="recipient_name">
              <Input
                placeholder="Input Your Account Holder Name"
                className="h-10"
              />
            </Form.Item>
            <Form.Item label="Account Number" name="recipient_account">
              <Input
                type="number"
                placeholder="Input Your Account Number"
                className="h-10"
              />
            </Form.Item>
            <Form.Item>
              <Button
                buttonType="submit"
                type="primary"
                title="CONTINUE"
                style={{ width: "100%", backgroundColor: colorTheme }}
                // isLoading={isLoading}
              />
              <Button
                buttonType="button"
                type="secondary"
                title="Skip for now"
                onClick={() => {
                  history.push({
                    pathname: "/dashboard",
                  });
                }}
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  color: colorTheme,
                }}
              />
            </Form.Item>
          </Form>
        </ConfigProvider>
      ) : selectedPayment === "xendit" ? (
        <FormXendit
          handleSubmit={handleSubmit}
          setTutorScrtKey={setTutorScrtKey}
          setTutorCopyPaste={setTutorCopyPaste}
          setTutorWebhookToken={setTutorWebhookToken}
          setSelectedPayment={setSelectedPayment}
        />
      ) : (
        <div className="flex justify-between md:w-[568px]">
          <Button
            type="outline-secondary"
            title={"Skip for now"}
            onClick={() => {
              history.push({
                pathname: "/dashboard",
              });
            }}
            style={{
              border: `1px solid ${colorTheme}`,
              color: colorTheme,
              borderRadius: 0,
            }}
          />

          <Button
            style={{
              backgroundColor: colorTheme,
              borderRadius: 0,
            }}
            type="primary"
            title="Connect"
            onClick={handleConnectStripe}
          />
        </div>
      )}

      <ModalTutorSecretKey
        setTutorScrtKey={setTutorScrtKey}
        tutorScrtKey={tutorScrtKey}
      />

      <ModalTutorCopas
        setTutorCopyPaste={setTutorCopyPaste}
        tutorCopyPaste={tutorCopyPaste}
      />

      <ModalTutorWebhookToken
        setTutorWebhookToken={setTutorWebhookToken}
        tutorWebhookToken={tutorWebhookToken}
      />
    </div>
  );
};

export default SettingPayment;
