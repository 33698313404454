import { Image, Modal } from "antd";
import React from "react";

import xenditcopas1 from "../../assets/images/copas1.svg";
import xenditcopas2 from "../../assets/images/copas2.svg";

const ModalTutorCopas = ({ tutorCopyPaste, setTutorCopyPaste }) => {
  return (
    <Modal
      centered
      open={tutorCopyPaste}
      closable={false}
      footer={false}
      width={745}
      onCancel={() => setTutorCopyPaste(false)}
    >
      <div className="grid gap-4 justify-items-center">
        <h4 className="mb-6">How to copy and paste Webhook URL</h4>
        <p className="mb-0">1.Copy the webhook URL from Gigs.</p>
        <p className="mb-0">
          2. In Xendit, go to <b>Settings {">"} Webhooks</b> .
        </p>
        <Image src={xenditcopas1} alt="" />
        <p className="mb-0">
          3. In the <b>Invoices</b> section, paste the URL into the{" "}
          <b>Invoices Paid </b>
          field. Click <b>Test and Save</b>
        </p>
        <Image src={xenditcopas2} alt="" />
      </div>
    </Modal>
  );
};

export default ModalTutorCopas;
