import React, { useEffect, useMemo, useRef, useState } from "react";

import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./../students.styles.scss";
import { Col } from "react-bootstrap";
import AtomLoading from "./../../../../components/loding-indicator";
import AtomNumberFormat from "./../../../../components/number-format";

import {
  getListOfferingPlans,
  getPublicPlans,
} from "../../../../redux/student/student.action";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import EmptyData from "../../../../components/no-data";
import { toast } from "react-toastify";
import { sendGetRequest, sendPostRequest } from "../../../../services/students";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import hexToRGBA from "../../../../helper/hexToRbga";
import { getDisbursementSetting } from "../../../../services/payment";
import ModalSuccessBuyPlan from "../../../../components/Modal/success-booking-plan";
import { Modal } from "antd";
import FormOTP from "../../../../components/OTP";
import StudioLoginForm from "../../../StudioLogin/login-form";
import { ModalLogin } from "../../../StudioLogin/modal";
import {
  requestOtp,
  setLoginFromStudio,
  setNewUser,
} from "../../../../redux/user/user.action";
import * as authService from "../../../../services/auth";
import { getStudioInfo } from "../../../../services/studios";

const BuyPlans = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);
  const [plans, setPlans] = useState([]);
  const [propsPayment, setPropsPayment] = useState();
  const [paymentGateway, setPaymentGateway] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [modalPending, setModalPending] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [studio_detail, setstudio_detail] = useState({ id: "" });
  const [colorTheme, setColorTheme] = useState("");

  const student_id = props.location.state?.student_id;
  const fromOwner = props.location.state?.fromOwner;

  const { payment_detail } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { isLoading, plan_list } = useSelector((state) => state.student);
  const { user } = useSelector((state) => state.user);

  const user_token = user?.token || cookies.user?.token;
  const studio_id = user?.studio_id || cookies.user?.studio_id;

  const planData = fromOwner || !user_token ? plans : plan_list;

  const plans_type_class_pass = planData?.filter(
    (item) => item?.plan_type_group === "class pass"
  );
  const plans_type_membership = planData?.filter(
    (item) => item?.plan_type_group === "membership"
  );
  const mobileSize = useMemo(() => window.screen.width < 576, []);
  const subdomain =
    process.env.NODE_ENV === "development"
      ? "vedic-staging"
      : window.location.host.split(".")[0];

  useEffect(() => {
    let url =
      paymentGateway === "stripe"
        ? propsPayment?.stripe_checkout_session_url
        : propsPayment?.payment?.gateway_response?.url;

    if (url !== undefined) {
      if (paymentGateway === "stripe") {
        window.location.href = url;
      } else if (paymentGateway === "oy") {
        window.open(url, "_blank");
      }
    }
  }, [
    paymentGateway,
    propsPayment?.payment?.gateway_response?.url,
    propsPayment?.stripe_checkout_session_url,
  ]);

  useEffect(() => {
    handleGetStudioInfo();
  }, []);

  const handleGetStudioInfo = async () => {
    try {
      const res = await getStudioInfo(subdomain);
      setstudio_detail(res.data.data);
      setColorTheme(res.data.data.studio_header_color);
    } catch (error) {}
  };

  const handleGetDisbursementSetting = async () => {
    try {
      const { data } = await getDisbursementSetting(user_token, studio_id);
      const { payment_gateway, recipient_bank_code } = data.data;

      setPaymentGateway(payment_gateway);
      setBankCode(recipient_bank_code);
    } catch (error) {
      console.log(error);
    }
  };

  const buyPlanOffline = async (plan_id) => {
    let params = {
      category: "plan",
      plan_id: plan_id,
      payment_method: "offline",
      payment_type: "cash",
      student_id: student_id,
    };

    try {
      await sendPostRequest(user_token, studio_id, "/orders", params);
      toast.success("Successfully Add Plan for Student!");
      // history.goBack();
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const buyPlan = async (plan_id) => {
    let params = {
      category: "plan",
      plan_id: plan_id,
      payment_method: "online",
      payment_type: "card",
      student_id: student_id,
    };
    if (paymentGateway === "stripe") {
      params.success_url = window.location.href;
    } else if (paymentGateway === "oy") {
      params.bank_code = bankCode;
    }

    try {
      const { data } = await sendPostRequest(
        user_token,
        studio_id,
        "/orders",
        params
      );
      setPropsPayment(data?.data);
      setModalSuccess(true);
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
    // history.push({
    //   pathname: "/choose-payment",
    //   state: { plan_id: plan_id },
    // });
  };

  console.log({ propsPayment });

  const handleBuyPlan = (plan_id) => {
    if (fromOwner) {
      buyPlanOffline(plan_id);
    } else {
      buyPlan(plan_id);
    }
  };

  const getStudentPlans = async () => {
    const baseUrl = "/offering-plans";
    const url = fromOwner ? `${baseUrl}?student_id=${student_id}` : baseUrl;

    try {
      const res = await sendGetRequest(user_token, studio_id, url);
      setPlans(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetPublicPlans = async () => {
    try {
      const { data } = await getPublicPlans(subdomain);
      setPlans(data.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (user_token) {
      handleGetDisbursementSetting();
      fromOwner
        ? getStudentPlans()
        : dispatch(getListOfferingPlans(user_token, studio_id));
    } else {
      handleGetPublicPlans();
    }
  }, [dispatch, fromOwner, studio_id, user_token]);

  const getStatusOrder = async () => {
    try {
      const { data } = await sendGetRequest(
        user_token,
        studio_id,
        `/orders/${propsPayment?._id}`
      );
      const { payment_status } = data.data.payment;
      if (payment_status === "settlement") {
        setPropsPayment(data.data);
        fromOwner
          ? getStudentPlans()
          : dispatch(getListOfferingPlans(user_token, studio_id));
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (propsPayment?._id) {
      const intervalId = setInterval(() => {
        getStatusOrder();
      }, 60000);

      return () => clearInterval(intervalId);
    }
  }, [propsPayment?._id]);

  const [email, setEmail] = useState("");
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [showModalOTP, setShowModalOTP] = useState(false);
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [registerWithSocmed, setRegisterWithSocmed] = useState(false);
  const [method, setMethod] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const handleClick = () => {
    dispatch(requestOtp(email, subdomain, true));
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const responseSocialAuth = async (provider, response) => {
    try {
      console.log(response, "response");

      const access_token = response?.accessToken;
      setMethod(provider);
      setAccessToken(access_token);
      setEmail(response.profileObj?.email ?? response?.email);

      if (access_token) {
        const result = await authService.loginWithSocmed(
          provider,
          access_token
        );
        if (result.data.data.token) {
          dispatch(setLoginFromStudio());
          dispatch(setNewUser(result.data?.data));
          setShowModalLogin(false);
        }
      }
    } catch (error) {
      if (error.response.data.message === "User not found") {
        setShowModalRegister(true);
        setRegisterWithSocmed(true);
      }
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const handleRegisterWithSocmed = async () => {
    try {
      const result = await authService.registerWithSocmed(method, accessToken);
      console.log(result, "result");
      if (result.data.data.token) {
        dispatch(setLoginFromStudio());
        dispatch(setNewUser(result.data?.data));
        history.push("/dashboard");
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const redirectToRegister = () => {
    registerWithSocmed
      ? handleRegisterWithSocmed()
      : history.push("/register-student");
  };

  return (
    <OrganismLayout
      studioDetail={studio_detail}
      isBrowseClasses={!user_token}
      colorTheme={colorTheme}
    >
      {isLoading ? (
        <AtomLoading />
      ) : (
        <div className={`text-center ${user_token && "max-w-screen-lg"}`}>
          <h2 className=" font-bold mb-5">Choose Plan</h2>
          {plans_type_class_pass?.length > 0 ? (
            <>
              <span className="font-bold text-gray-600">Based on Credits</span>
              <div className="mt-3 flex gap-4 overflow-auto">
                {plans_type_class_pass[0]?.plan_data
                  .filter((data) => data.status_plan === "active")
                  .map((el, idx) => (
                    <div key={idx}>
                      <CardPlan
                        data={el}
                        isLoggedin={user_token}
                        colorTheme={colorTheme}
                        paymentDetail={payment_detail}
                        typeCard={"Credit"}
                        handleBuyPlan={handleBuyPlan}
                        setShowModalLogin={setShowModalLogin}
                      />
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <>
              <span>Based on Credits</span>
              <div className="py-5">
                <EmptyData />
              </div>
            </>
          )}

          {plans_type_membership?.length > 0 ? (
            <>
              <span className="font-bold text-gray-600">Based on Session</span>
              <div className="mt-3 flex gap-4 max-w-screen overflow-auto">
                {plans_type_membership[0]?.plan_data
                  .filter((data) => data.status_plan === "active")
                  .map((el, idx) => (
                    <div key={idx}>
                      <CardPlan
                        data={el}
                        isLoggedin={user_token}
                        colorTheme={colorTheme}
                        paymentDetail={payment_detail}
                        typeCard={"Session"}
                        handleBuyPlan={handleBuyPlan}
                        setShowModalLogin={setShowModalLogin}
                      />
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <>
              <span>Based on Session</span>
              <div className="py-5">
                <EmptyData />
              </div>
            </>
          )}
        </div>
      )}

      {/* Login */}
      <Modal
        width={400}
        footer={null}
        open={showModalLogin}
        onCancel={() => {
          showModalLogin ? setShowModalLogin(false) : setShowModalOTP(false);
        }}
      >
        {showModalOTP ? (
          <FormOTP subdomain={subdomain} />
        ) : (
          <StudioLoginForm
            colorTheme={colorTheme}
            email={email}
            handleClick={handleClick}
            handleChange={handleChange}
            responseSocialAuth={responseSocialAuth}
            hideBrowseClasses
          />
        )}
      </Modal>

      <ModalLogin
        colorTheme={colorTheme}
        show={showModalRegister}
        title="Email Not Registered"
        label="Continue register with"
        email={email}
        titleButton1={"CHANGE"}
        titleButton2={"CONFIRM"}
        handleClose={() => setShowModalRegister(false)}
        handleSubmit={redirectToRegister}
      />

      <ModalSuccessBuyPlan
        propsPayment={propsPayment}
        setPropsPayment={setPropsPayment}
        selectedPlanDetail={propsPayment?.plan}
        open={modalSuccess}
        setOpen={setModalSuccess}
        colorTheme={colorTheme}
      />
    </OrganismLayout>
  );
};

const CardPlan = ({
  data,
  colorTheme,
  isLoggedin,
  payment_detail,
  typeCard,
  handleBuyPlan,
  setShowModalLogin,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{
        minHeight: "20rem",
        border: `1px solid ${hexToRGBA(colorTheme, 0.2)}`,
        backgroundColor: hover ? colorTheme : "white",
      }}
      className={`Rectangle-15 p-3 mb-5 mx-auto d-flex flex-column justify-content-between  custom-hover5 `}
    >
      {typeCard === "Credit" ? (
        <>
          <h6>{data?.plan_name}</h6>
          <b>{data?.credits} Credits</b>
          <h5>
            <AtomNumberFormat prefix="IDR " value={data?.total_amount} />
          </h5>
          <p className="py-3 fs-12 overflow-hidden">{data?.notes}</p>
          <span className="fs-12">{data?.duration}</span> <br />
          {data?.user_has_active_plan ? (
            <span className="fs-10 text-danger mb-3">
              You already have this plan
            </span>
          ) : (
            <div className=""></div>
          )}
          <button
            onClick={() =>
              isLoggedin
                ? handleBuyPlan(data?.plan_id)
                : setShowModalLogin(true)
            }
            style={{
              color: hover ? colorTheme : "white",
              backgroundColor: hover ? "white" : colorTheme,
              borderRadius: 0,
            }}
            disabled={
              (data?.user_has_active_plan &&
                data?.user_has_pending_payment === false) ||
              (data?.user_has_active_plan === false &&
                data?.user_has_pending_payment) ||
              (payment_detail?.bank_transfer === false &&
                payment_detail?.online_payment === false)
            }
            className={`${
              (data?.user_has_active_plan &&
                data?.user_has_pending_payment === false) ||
              (data?.user_has_active_plan === false &&
                data?.user_has_pending_payment)
                ? "disabled"
                : ""
            }  justify-content-center border-0 fw-bold  w-100 p-2`}
          >
            {data?.user_has_active_plan &&
            data?.user_has_pending_payment === false
              ? "REGISTERED"
              : data?.user_has_active_plan === false &&
                data?.user_has_pending_payment
              ? "PENDING"
              : payment_detail?.bank_transfer === false &&
                payment_detail?.online_payment === false
              ? "NOT AVAILABLE"
              : "BOOK NOW"}
          </button>
        </>
      ) : (
        <>
          <h6>{data?.plan_name}</h6>
          <b>{data?.session} Session</b>
          <h5>
            <AtomNumberFormat prefix="IDR " value={data?.total_amount} />
          </h5>
          <p className="py-3 fs-12 overflow-hidden">{data?.notes}</p>
          <span className="fs-12">{data?.duration}</span> <br />
          {data?.user_has_active_plan &&
          data?.user_has_pending_payment === false ? (
            <span className="fs-10 text-danger mb-3">
              You already have this plan
            </span>
          ) : (
            <div className=""></div>
          )}
          <button
            onClick={() =>
              isLoggedin
                ? handleBuyPlan(data?.plan_id)
                : setShowModalLogin(true)
            }
            style={{
              color: hover ? colorTheme : "white",
              backgroundColor: hover ? "white" : colorTheme,
              borderRadius: 0,
            }}
            disabled={
              (data?.user_has_active_plan &&
                data?.user_has_pending_payment === false) ||
              (data?.user_has_active_plan === false &&
                data?.user_has_pending_payment) ||
              (payment_detail?.bank_transfer === false &&
                payment_detail?.online_payment === false)
            }
            className={`${
              (data?.user_has_active_plan &&
                data?.user_has_pending_payment === false) ||
              (data?.user_has_active_plan === false &&
                data?.user_has_pending_payment)
                ? "disabled"
                : ""
            }  justify-content-center border-0 fw-bold  w-100 p-2`}
          >
            {data?.user_has_active_plan &&
            data?.user_has_pending_payment === false
              ? "REGISTERED"
              : data?.user_has_active_plan === false &&
                data?.user_has_pending_payment
              ? "PENDING"
              : payment_detail?.bank_transfer === false &&
                payment_detail?.online_payment === false
              ? "NOT AVAILABLE"
              : "BOOK NOW"}
          </button>
        </>
      )}
    </div>
  );
};

export default BuyPlans;
