import { Image, Modal } from "antd";
import React from "react";
import xenditkey1 from "../../assets/images/xenditkey1.svg";
import xenditkey2 from "../../assets/images/xenditkey2.svg";
import xenditkey3 from "../../assets/images/xenditkey3.svg";
import xenditkey4 from "../../assets/images/xenditkey4.svg";

const ModalTutorSecretKey = ({ tutorScrtKey, setTutorScrtKey }) => {
  return (
    <Modal
      centered
      open={tutorScrtKey}
      closable={false}
      footer={false}
      width={695}
      onCancel={() => setTutorScrtKey(false)}
    >
      <div className="grid gap-4 justify-items-center">
        <h4 className="mb-6">How to generate Xendit secret key</h4>
        <p className="mb-0">
          1. Go to <b>Settings</b> {">"} <b>API Keys</b> .
        </p>
        <Image src={xenditkey1} alt="" />
        <p className="mb-0">
          2. Click <b>Generate Keys</b>.
        </p>
        <Image src={xenditkey2} alt="" />
        <p className="mb-0">
          3. Enter an API key name and ensure the <b>Money-In-Product</b>{" "}
          permission is set to <b>Write</b>. Click<b> Generate Keys</b>.
        </p>
        <Image src={xenditkey3} alt="" />
        <p className="mb-0">
          4. Once created, copy the <b>Secret API Key</b>.
        </p>
        <Image src={xenditkey4} alt="" />
        <span>You can also refer to the guide in the following video.</span>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/v4fOSJ1YoBA?si=g6r3EfuQ7cAGhcCo"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
    </Modal>
  );
};

export default ModalTutorSecretKey;
