import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import "../classes.styles.scss";

import dayjs from "dayjs";

import { Col } from "react-bootstrap";
// import Button from "../../../../../components/Button/button.component";

import {
  addIdTeacher,
  addClass,
  removeIdTeacher,
  setStatusDefault,
  getTeacher,
  updateClass,
  addTeacher,
  updateSchedule,
  setStatusDefaultAddTeacher,
} from "../../../../../redux/owner/owner.action";
import { toast } from "react-toastify";
import {
  Form,
  Button,
  Select,
  ConfigProvider,
  Input,
  Tag,
  Checkbox,
} from "antd";
import hexToRGBA from "../../../../../helper/hexToRbga";
import UploadPicture from "../../../../../components/uploadPicture";

const { Option } = Select;

const ClassAddTeacherSetup = ({ prevStepSchedule, id, setStepSchedule }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies] = useCookies(["subdomain", "user"]);

  const [teachers, setTeachers] = useState([]);
  const [addNewTeacher, setAddNewTeacher] = useState(false);
  const [pictureData, setPictureData] = useState(null);

  const {
    class_id,
    addClass: addClassReducer,
    teacher,
    isLoadingClass,
    addClassStatus,
    updateClassStatus,
    class_detail,
    addTeacherStatus,
    updateScheduleStatus,
  } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );

  const user_token = user?.token || cookies.user?.token;
  const studio_id = user?.studio_id || cookies.user?.studio_id;
  const isCreating = window.location.pathname.includes("add") ? true : false;

  let data_teachers = isCreating ? addClassReducer.teacher : teachers;

  useEffect(() => {
    dispatch(getTeacher(user_token, studio_id, 1));
  }, []);

  const handleAddIdTeacher = (id) => {
    const findIdTeacher = isCreating
      ? data_teachers.find((x) => x.teacher_id === id)
      : data_teachers.find((x) => x.id === id);

    if (id === "") {
      toast.error("Please Choose a Teacher");
      return;
    } else if (findIdTeacher) {
      toast.error("Teacher already exist!");
      return;
    }

    let dataTeacher = teacher.data?.find((e) => e?.id === id);

    let params = isCreating
      ? {
          teacher_id: dataTeacher?.id,
          first_name: dataTeacher?.first_name,
          last_name: dataTeacher?.last_name,
        }
      : {
          id: dataTeacher?.id,
          first_name: dataTeacher?.first_name,
          last_name: dataTeacher?.last_name,
        };
    isCreating
      ? dispatch(addIdTeacher(params))
      : setTeachers([...teachers, params]);
  };

  const filterValidSchedules = (schedules) => {
    return schedules.filter((schedule) => {
      const isValidStart = dayjs(schedule.start_time, "HH:mm", true).isValid();
      const isValidEnd = dayjs(schedule.end_time, "HH:mm", true).isValid();
      return isValidStart && isValidEnd;
    });
  };

  const handleSubmit = async () => {
    if (data_teachers.length === 0) {
      toast.error("Please Add Teacher");
      return;
    }
    let teacherIds = "";
    data_teachers.map((e, idx) => {
      if (idx === 0) {
        return (teacherIds = e.teacher_id ?? e.id);
      } else {
        return (teacherIds = teacherIds.concat(",", e.teacher_id ?? e.id));
      }
    });

    const validSchedules = filterValidSchedules(addClassReducer.schedule);

    let params = {
      class_name: addClassReducer.class_name,
      is_online: addClassReducer.is_online,
      location_ids: addClassReducer?.location_ids,
      room: addClassReducer.room,
      slots: addClassReducer.slots,
      notes: addClassReducer.notes,
      start_date: addClassReducer.start_date,
      end_date: addClassReducer.end_date,
      repeat_every: addClassReducer.repeat_every,
      repeat_duration: addClassReducer.repeat_duration,
      teacher_ids: teacherIds,
      direct_payment_allowed: addClassReducer.direct_payment_allowed,
      credits: addClassReducer.credits,
      all_plans_allowed: addClassReducer.all_plans_allowed,
      class_categories: addClassReducer.class_categories.map((el) => el.label),
      about_the_class: addClassReducer.about_the_class,
    };

    if (!addClassReducer.all_plans_allowed) {
      params.plan_ids = [addClassReducer.plan_ids];
    }

    // Add online class-specific properties
    if (addClassReducer.is_online === 1) {
      params.class_link = addClassReducer.class_link;
      params.additional_information = addClassReducer.additional_information;
    }

    // Add payment-related properties
    if (addClassReducer.direct_payment_allowed) {
      params.class_price = addClassReducer.class_price;
      params.class_tax = addClassReducer.class_tax;
    }

    // Add schedule if it's a new class
    if (isCreating) {
      params.schedule = validSchedules;
    }

    console.log(params);

    if (isCreating) {
      dispatch(addClass(params, user_token, studio_id));
    } else {
      // dispatch(
      //   updateSchedule(
      //     {
      //       start_date: addClassReducer.start_date,
      //       end_date: addClassReducer.end_date,
      //       repeat_every: addClassReducer.repeat_every,
      //       repeat_duration: addClassReducer.repeat_duration,
      //       schedule: validSchedules,
      //     },
      //     user_token,
      //     studio_id,
      //     id
      //   )
      // );
      // if (updateScheduleStatus === "succes") {
      dispatch(updateClass(params, user_token, studio_id, id));
      // }
    }
  };

  const removeTeacher = (id) => {
    const afterDelete = data_teachers.filter((teacher) => teacher.id !== id);

    if (isCreating) {
      dispatch(removeIdTeacher(afterDelete));
    } else {
      setTeachers(afterDelete);
    }
  };

  useEffect(() => {
    if (addClassStatus === "success" || updateClassStatus === "success") {
      history.push({
        pathname: `/admin/class/${class_id?._id}/detail`,
        state: { id: class_id?._id },
      });
      dispatch(setStatusDefault());
    }
  }, [addClassStatus, updateClassStatus]);

  useEffect(() => {
    if (!isCreating) setTeachers(class_detail?.teachers_detail);
  }, [isCreating, class_detail]);

  useEffect(() => {
    if (addTeacherStatus === "success") {
      setAddNewTeacher(false);
      dispatch(setStatusDefaultAddTeacher());
      dispatch(getTeacher(user_token, studio_id, 1));
    }
  }, [addTeacherStatus, dispatch, studio_id, user_token]);

  const handleSubmitTeacher = ({
    first_name,
    last_name,
    email,
    phone_number,
    send_welcome_email,
    gender,
  }) => {
    let params = {
      first_name,
      last_name,
      email,
      send_welcome_email: send_welcome_email ? 1 : 0,
      gender,
    };
    if (phone_number) params.phone_number = phone_number;
    if (pictureData) params.profile_picture = pictureData;

    dispatch(addTeacher(params, user_token, studio_id));
  };

  return (
    <Col lg={6}>
      {!addNewTeacher ? (
        <Form onFinish={handleSubmit} className="w-full">
          <div className="w-full flex flex-col gap-3">
            <Form.Item layout="vertical" label="Choose Teacher">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: studio_detail?.styles?.header_color,
                  },
                }}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  size="large"
                  required={data_teachers?.length === 0}
                  onChange={(value) => handleAddIdTeacher(value)}
                  placeholder="Select a teacher"
                  options={teacher.data?.map((item) => {
                    return {
                      label: item?.first_name + " " + item?.last_name,
                      value: item?.id,
                    };
                  })}
                  className="w-full"
                />
              </ConfigProvider>
            </Form.Item>

            <div className="w-full flex ">
              {data_teachers.length > 0 &&
                data_teachers.map((el) => (
                  <Tag
                    closable
                    key={el.id}
                    onClose={() => removeTeacher(el.id)}
                    className="p-1 m-1"
                    style={{
                      backgroundColor: hexToRGBA(
                        studio_detail?.styles?.header_color,
                        0.2
                      ),
                      color: studio_detail?.styles?.header_color,
                    }}
                  >
                    {el?.first_name} {el?.last_name}
                  </Tag>
                ))}
            </div>

            <div className="flex flex-row w-full justify-center">
              <Button
                type="default"
                size="large"
                onClick={() => setAddNewTeacher(true)}
                className="rounded-sm"
                style={{
                  color: studio_detail?.styles?.header_color,
                  border: `1px solid ${studio_detail?.styles?.header_color}`,
                }}
              >
                Add New Teacher
              </Button>
            </div>

            <div className="w-full flex flex-col gap-3">
              <Button
                loading={isLoadingClass}
                type="primary"
                htmlType="submit"
                size="large"
                className="rounded-sm"
                style={{
                  backgroundColor: studio_detail?.styles?.header_color,
                }}
              >
                {isCreating ? "Create Class" : "Save"}
              </Button>

              <Button
                type="text"
                size="large"
                className="rounded-sm"
                style={{
                  color: studio_detail?.styles?.header_color,
                }}
                onClick={() => {
                  isCreating ? prevStepSchedule() : setStepSchedule(1);
                }}
              >
                Back
              </Button>
            </div>
          </div>
        </Form>
      ) : (
        // create a form add new teacher
        <Form
          onFinish={handleSubmitTeacher}
          className="w-full"
          layout="vertical"
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            gender: "",
          }}
        >
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: studio_detail?.styles?.header_color,
              },
            }}
          >
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please input your first name!",
                },
              ]}
            >
              <Input placeholder="First Name" className="w-full" size="large" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Please input your last name!",
                },
              ]}
            >
              <Input placeholder="Last Name" className="w-full" size="large" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input placeholder="Email" className="w-full" size="large" />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
              ]}
            >
              <Input
                placeholder="Phone Number"
                className="w-full"
                size="large"
              />
            </Form.Item>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Please select your gender!",
                },
              ]}
            >
              <Select
                placeholder="Select your gender"
                className="w-full"
                size="large"
              >
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
              </Select>
            </Form.Item>
          </ConfigProvider>
          <UploadPicture
            themeColor={studio_detail?.styles?.header_color}
            valuePicture={pictureData}
            fileData={(file) => setPictureData(file)}
          />
          <Form.Item name="send_welcome_email" valuePropName="checked">
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: studio_detail?.styles?.header_color,
                },
              }}
            >
              <Checkbox>Send welcome email</Checkbox>
            </ConfigProvider>
          </Form.Item>
          <div className="w-full flex flex-col gap-3">
            <Button
              loading={isLoadingClass}
              type="primary"
              htmlType="submit"
              size="large"
              className="rounded-sm"
              style={{
                backgroundColor: studio_detail?.styles?.header_color,
              }}
            >
              Add Teacher
            </Button>

            <Button
              type="text"
              size="large"
              className="rounded-sm"
              style={{
                color: studio_detail?.styles?.header_color,
              }}
              onClick={() => {
                setAddNewTeacher(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Col>
  );
};

export default ClassAddTeacherSetup;
