import OwnerActionTypes from "./owner.types";

const INITIAL_STATE = {
  addClass: {
    type: "",
    class_name: "",
    is_online: 0,
    class_link: null,
    additional_information: "",
    location_ids: [],
    room: "",
    slots: "",
    notes: "",
    direct_payment_allowed: 0,
    class_price: null,
    class_tax: null,
    credits: "",
    all_plans_allowed: "1",
    plan_ids: [],
    plans_data: [],
    start_date: "",
    stop_repeating: "",
    repeat_amount: "",
    repeat_duration: "",
    schedule: [],
    teacher: [],
    class_categories: [],
    about_the_class: "",
  },
  isLoading: false,
  status: "default",
  isLoadingStudent: false,
  addStudentStatus: "default",
  updateStudentStatus: "default",
  addNotesStatus: "default",
  isLoadingTeacher: false,
  addTeacherStatus: "default",
  updateTeacherStatus: "default",
  isLoadingClass: false,
  isLoadingDetail: false,
  isLoadingClassParticipant: false,
  addClassStatus: "default",
  updateClassStatus: "default",
  updateScheduleStatus: "default",
  getClassStatus: "default",
  isLoadingPlan: false,
  addPlanStatus: "default",
  isLoadingPayment: false,
  students: [],
  add_student_res: [],
  student_detail: [],
  student_class: [],
  student_plan: [],
  student_notes: [],
  student_order: [],
  teacher: [],
  add_teacher_res: [],
  teacher_detail: [],
  owner_classes: [],
  class_id: [],
  class_detail: [],
  class_participant: [],
  class_time_participant: [],
  plan: [],
  payment_detail: [],
  plans: [],
  detail_plan: {},
  deactive_status: "default",
  profile_statistic: {},
  list_payment_verification: [],
  list_cancellation_request: [],
  list_upcoming_class: [],
  list_class_in_progress: [],
  booking_history: [],
  onboarding_status: [],
  addLocationStatus: "default",
};

const OwnerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OwnerActionTypes.CLASSNAME_SETUP:
      return {
        ...state,
        addClass: {
          ...state.addClass,
          type: action.payload.type,
          class_name: action.payload.class_name,
          is_online: action.payload.is_online,
          class_link: action.payload.class_link,
          additional_information: action.payload.additional_information,
          location_ids: action.payload.location_ids,
          room: action.payload.room,
          slots: action.payload.slots,
          notes: action.payload.notes,
          direct_payment_allowed: action.payload.direct_payment_allowed,
          class_price: action.payload.class_price,
          class_tax: action.payload.class_tax,
          credits: action.payload.credits,
          all_plans_allowed: action.payload.all_plans_allowed,
          plan_ids: action.payload.plan_ids,
          plans_data: action.payload.plans_data,
          class_categories: action.payload.class_categories,
          about_the_class: action.payload.about_the_class,
        },
      };
    case OwnerActionTypes.ADD_SCHEDULE:
      return {
        ...state,
        addClass: {
          ...state.addClass,
          schedule: state.addClass.schedule.concat(action.payload),
        },
      };
    case OwnerActionTypes.REMOVE_SCHEDULE:
      return {
        ...state,
        addClass: {
          ...state.addClass,
          schedule: action.payload,
        },
      };
    case OwnerActionTypes.ADD_DURATION:
      return {
        ...state,
        addClass: {
          ...state.addClass,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
          // stop_repeating: action.payload.stop_repeating,
          repeat_every: action.payload.repeat_every,
          repeat_duration: action.payload.repeat_duration,
        },
      };
    case OwnerActionTypes.ADD_IDTEACHER:
      return {
        ...state,
        addClass: {
          ...state.addClass,
          teacher: state.addClass.teacher.concat(action.payload),
        },
      };
    case OwnerActionTypes.CLEAR_IDTEACHER:
      return {
        ...state,
        add_teacher_res: [],
      };
    case OwnerActionTypes.CLEAR_CLASS_DETAIL:
      return {
        ...state,
        class_detail: [],
      };
    case OwnerActionTypes.REMOVE_IDTEACHER:
      return {
        ...state,
        addClass: {
          ...state.addClass,
          teacher: action.payload,
        },
      };
    case OwnerActionTypes.ADD_STUDENT:
      return {
        ...state,
        isLoadingStudent: true,
        addStudentStatus: "default",
      };
    case OwnerActionTypes.ADD_STUDENT_SUCCESS:
      return {
        ...state,
        isLoadingStudent: false,
        add_student_res: action.payload,
        addStudentStatus: "success",
      };
    case OwnerActionTypes.ADD_STUDENT_FAILED:
      return {
        ...state,
        isLoadingStudent: false,
        message: action.message,
        addStudentStatus: "failed",
      };
    case OwnerActionTypes.ADD_STUDENT_NOTES:
      return {
        ...state,
        isLoadingStudent: true,
        addNoteStatus: "default",
      };
    case OwnerActionTypes.ADD_STUDENT_NOTES_SUCCESS:
      return {
        ...state,
        isLoadingStudent: false,
        addNoteStatus: "success",
      };
    case OwnerActionTypes.ADD_STUDENT_NOTES_FAILED:
      return {
        ...state,
        isLoadingStudent: false,
        message: action.message,
        addNoteStatus: "failed",
      };
    case OwnerActionTypes.GET_STUDENT_DETAIL:
      return {
        ...state,
        isLoadingStudent: true,
        status: "default",
      };
    case OwnerActionTypes.GET_STUDENT_DETAIL_SUCCESS:
      return {
        ...state,
        isLoadingStudent: false,
        student_detail: action.payload,
        status: "success",
      };
    case OwnerActionTypes.GET_STUDENT_DETAIL_FAILED:
      return {
        ...state,
        isLoadingStudent: false,
        message: action.message,
        status: "failed",
      };
    case OwnerActionTypes.GET_STUDENT_CLASS:
      return {
        ...state,
        isLoadingStudent: true,
        status: "default",
      };
    case OwnerActionTypes.GET_STUDENT_CLASS_SUCCESS:
      return {
        ...state,
        isLoadingStudent: false,
        student_class: action.payload,
        status: "success",
      };
    case OwnerActionTypes.GET_STUDENT_CLASS_FAILED:
      return {
        ...state,
        isLoadingStudent: false,
        message: action.message,
        status: "failed",
      };
    case OwnerActionTypes.GET_STUDENT_PLAN:
      return {
        ...state,
        isLoadingStudent: true,
        status: "default",
      };
    case OwnerActionTypes.GET_STUDENT_PLAN_SUCCESS:
      return {
        ...state,
        isLoadingStudent: false,
        student_plan: action.payload,
        status: "success",
      };
    case OwnerActionTypes.GET_STUDENT_PLAN_FAILED:
      return {
        ...state,
        isLoadingStudent: false,
        message: action.message,
        status: "failed",
      };
    case OwnerActionTypes.GET_STUDENT_NOTES:
      return {
        ...state,
        isLoadingStudent: true,
        status: "default",
      };
    case OwnerActionTypes.GET_STUDENT_NOTES_SUCCESS:
      return {
        ...state,
        isLoadingStudent: false,
        student_notes: action.payload,
        status: "success",
      };
    case OwnerActionTypes.GET_STUDENT_NOTES_FAILED:
      return {
        ...state,
        isLoadingStudent: false,
        message: action.message,
        status: "failed",
      };
    case OwnerActionTypes.GET_STUDENT_ORDER:
      return {
        ...state,
        isLoadingStudent: true,
        status: "default",
      };
    case OwnerActionTypes.GET_STUDENT_ORDER_SUCCESS:
      return {
        ...state,
        isLoadingStudent: false,
        student_order: action.payload,
        status: "success",
      };
    case OwnerActionTypes.GET_STUDENT_ORDER_FAILED:
      return {
        ...state,
        isLoadingStudent: false,
        message: action.message,
        status: "failed",
      };
    case OwnerActionTypes.UPDATE_STUDENT:
      return {
        ...state,
        isLoadingStudent: true,
        updateStudentStatus: "default",
      };
    case OwnerActionTypes.UPDATE_STUDENT_SUCCESS:
      return {
        ...state,
        isLoadingStudent: false,
        updateStudentStatus: "success",
      };
    case OwnerActionTypes.UPDATE_STUDENT_FAILED:
      return {
        ...state,
        isLoadingStudent: false,
        message: action.message,
        updateStudentStatus: "failed",
      };
    case OwnerActionTypes.ADD_TEACHER:
      return {
        ...state,
        isLoadingTeacher: true,
        addTeacherStatus: "default",
      };
    case OwnerActionTypes.ADD_TEACHER_SUCCESS:
      return {
        ...state,
        isLoadingTeacher: false,
        add_teacher_res: action.payload,
        addTeacherStatus: "success",
      };
    case OwnerActionTypes.ADD_TEACHER_FAILED:
      return {
        ...state,
        isLoadingTeacher: false,
        message: action.message,
        addTeacherStatus: "failed",
      };
    case OwnerActionTypes.SET_STATUS_DEFAULT_ADD_TEACHER:
      return {
        ...state,
        addTeacherStatus: "default",
      };
    case OwnerActionTypes.GET_TEACHER_DETAIL:
      return {
        ...state,
        isLoadingTeacher: true,
        status: "default",
      };
    case OwnerActionTypes.GET_TEACHER_DETAIL_SUCCESS:
      return {
        ...state,
        isLoadingTeacher: false,
        teacher_detail: action.payload,
        status: "success",
      };
    case OwnerActionTypes.GET_TEACHER_DETAIL_FAILED:
      return {
        ...state,
        isLoadingTeacher: false,
        message: action.message,
        status: "failed",
      };
    case OwnerActionTypes.UPDATE_TEACHER:
      return {
        ...state,
        isLoadingTeacher: true,
        updateTeacherStatus: "default",
      };
    case OwnerActionTypes.UPDATE_TEACHER_SUCCESS:
      return {
        ...state,
        isLoadingTeacher: false,
        updateTeacherStatus: "success",
      };
    case OwnerActionTypes.UPDATE_TEACHER_FAILED:
      return {
        ...state,
        isLoadingTeacher: false,
        message: action.message,
        updateTeacherStatus: "failed",
      };
    case OwnerActionTypes.ADD_CLASS:
      return {
        ...state,
        isLoadingClass: true,
        addClassStatus: "default",
      };
    case OwnerActionTypes.ADD_CLASS_SUCCESS:
      return {
        ...state,
        isLoadingClass: false,
        class_id: action.payload,
        addClassStatus: "success",
      };
    case OwnerActionTypes.ADD_CLASS_FAILED:
      return {
        ...state,
        isLoadingClass: false,
        message: action.message,
        addClassStatus: "failed",
      };
    case OwnerActionTypes.UPDATE_CLASS:
      return {
        ...state,
        isLoadingClass: true,
        updateClassStatus: "default",
      };
    case OwnerActionTypes.UPDATE_CLASS_SUCCESS:
      return {
        ...state,
        isLoadingClass: false,
        class_id: action.payload,
        updateClassStatus: "success",
      };
    case OwnerActionTypes.UPDATE_CLASS_FAILED:
      return {
        ...state,
        isLoadingClass: false,
        message: action.message,
        updateClassStatus: "failed",
      };
    case OwnerActionTypes.ACTIVATE_CLASS:
      return {
        ...state,
        isLoading: true,
      };
    case OwnerActionTypes.ACTIVATE_CLASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case OwnerActionTypes.ACTIVATE_CLASS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case OwnerActionTypes.GET_UPDATED_SCHEDULE:
      return {
        ...state,
        updateScheduleStatus: "default",
        // isLoadingClass: true,
      };
    case OwnerActionTypes.GET_UPDATED_SCHEDULE_SUCCESS:
      return {
        ...state,
        // isLoadingClass: false,
        updateScheduleStatus: "success",
        booking_history: action.payload,
      };
    case OwnerActionTypes.GET_UPDATED_SCHEDULE_FAILED:
      return {
        ...state,
        // isLoadingClass: false,
        updateScheduleStatus: "failed",
        message: action.message,
      };
    case OwnerActionTypes.GET_CLASS:
      return {
        ...state,
        isLoadingClass: true,
        getClassStatus: "default",
      };

    case OwnerActionTypes.GET_CLASS_SUCCESS:
      return {
        ...state,
        isLoadingClass: false,
        owner_classes: action.payload,
        getClassStatus: "success",
      };
    case OwnerActionTypes.GET_CLASS_FAILED:
      return {
        ...state,
        isLoadingClass: false,
        message: action.message,
        getClassStatus: "failed",
      };
    case OwnerActionTypes.GET_CLASS_DETAIL:
      return {
        ...state,
        isLoadingClassDetail: true,
      };

    case OwnerActionTypes.GET_CLASS_DETAIL_SUCCESS:
      return {
        ...state,
        isLoadingClassDetail: false,
        class_detail: action.payload,
      };
    case OwnerActionTypes.GET_CLASS_DETAIL_FAILED:
      return {
        ...state,
        isLoadingClassDetail: false,
        message: action.message,
      };
    case OwnerActionTypes.GET_CLASS_PARTICIPANT:
      return {
        ...state,
        isLoadingClassParticipant: true,
      };

    case OwnerActionTypes.GET_CLASS_PARTICIPANT_SUCCESS:
      return {
        ...state,
        isLoadingClassParticipant: false,
        class_participant: action.payload,
      };
    case OwnerActionTypes.GET_CLASS_PARTICIPANT_FAILED:
      return {
        ...state,
        isLoadingClassParticipant: false,
        message: action.message,
      };
    case OwnerActionTypes.GET_CLASS_TIME_PARTICIPANT:
      return {
        ...state,
        // isLoadingClassParticipant: true,
      };

    case OwnerActionTypes.GET_CLASS_TIME_PARTICIPANT_SUCCESS:
      return {
        ...state,
        // isLoadingClassParticipant: false,
        class_time_participant: action.payload,
      };
    case OwnerActionTypes.GET_CLASS_TIME_PARTICIPANT_FAILED:
      return {
        ...state,
        // isLoadingClassParticipant: false,
        message: action.message,
      };
    case OwnerActionTypes.ADD_PLAN:
      return {
        ...state,
        isLoadingPlan: true,
        addPlanStatus: "default",
      };
    case OwnerActionTypes.ADD_PLAN_SUCCESS:
      return {
        ...state,
        isLoadingPlan: false,
        plan: action.payload,
        addPlanStatus: "success",
      };
    case OwnerActionTypes.ADD_PLAN_FAILED:
      return {
        ...state,
        isLoadingPlan: false,
        message: action.message,
        addPlanStatus: "failed",
      };
    case OwnerActionTypes.UPDATE_PLAN:
      return {
        ...state,
        isLoadingPlan: true,
        addPlanStatus: "default",
      };
    case OwnerActionTypes.UPDATE_PLAN_SUCCESS:
      return {
        ...state,
        isLoadingPlan: false,
        addPlanStatus: "success",
      };
    case OwnerActionTypes.UPDATE_PLAN_FAILED:
      return {
        ...state,
        isLoadingPlan: false,
        message: action.message,
        addPlanStatus: "failed",
      };
    case OwnerActionTypes.SET_STATUS_DEFAULT:
      return {
        ...state,
        addClass: {
          type: "",
          class_name: "",
          is_online: 0,
          class_link: null,
          additional_information: "",
          location_ids: [],
          room: "",
          slots: "",
          notes: "",
          direct_payment_allowed: "0",
          class_price: "",
          class_tax: "",
          credits: "",
          all_plans_allowed: "1",
          plan_ids: [],
          plans_data: [],
          start_date: "",
          stop_repeating: "",
          repeat_amount: "",
          repeat_duration: "",
          schedule: [],
          teacher: [],
          class_categories: [],
          about_the_class: "",
        },
        add_student_res: [],
        add_teacher_res: [],
        addNoteStatus: "default",
        addStudentStatus: "default",
        updateStudentStatus: "default",
        updateTeacherStatus: "default",
        addClassStatus: "default",
        updateClassStatus: "default",
        updateScheduleStatus: "default",
        addPlanStatus: "default",
        deactive_status: "default",
      };
    case OwnerActionTypes.GET_STUDENTS:
      return {
        ...state,
        isLoading: true,
      };
    case OwnerActionTypes.GET_STUDENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        students: action.payload,
      };
    case OwnerActionTypes.GET_STUDENTS_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.message,
      };
    case OwnerActionTypes.GET_TEACHERS:
      return {
        ...state,
        isLoadingTeacher: true,
      };
    case OwnerActionTypes.GET_TEACHERS_SUCCESS:
      return {
        ...state,
        isLoadingTeacher: false,
        teacher: action.payload,
      };
    case OwnerActionTypes.GET_TEACHERS_FAILED:
      return {
        ...state,
        isLoadingTeacher: false,
        message: action.message,
      };
    case OwnerActionTypes.GET_PAYMENT_DETAIL:
      return {
        ...state,
        isLoadingPayment: true,
        status: "default",
      };
    case OwnerActionTypes.GET_PAYMENT_DETAIL_SUCCESS:
      return {
        ...state,
        isLoadingPayment: false,
        payment_detail: action.payload,
        status: "success",
      };
    case OwnerActionTypes.GET_PAYMENT_DETAIL_FAILED:
      return {
        ...state,
        isLoadingPayment: false,
        message: action.message,
        status: "failed",
      };
    case OwnerActionTypes.GET_PLANS:
      return {
        ...state,
        isLoading: true,
      };
    case OwnerActionTypes.GET_PLANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        plans: action.payload,
      };
    case OwnerActionTypes.GET_PLANS_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.message,
      };
    case OwnerActionTypes.GET_DETAIL_PLANS:
      return {
        ...state,
        isLoading: true,
      };
    case OwnerActionTypes.GET_DETAIL_PLANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detail_plan: action.payload,
      };
    case OwnerActionTypes.GET_DETAIL_PLANS_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.message,
      };
    case OwnerActionTypes.DEACTIVE_PLANS:
      return {
        ...state,
        isLoading: true,
      };
    case OwnerActionTypes.DEACTIVE_PLANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deactive_status: "success",
      };
    case OwnerActionTypes.DEACTIVE_PLANS_FAILED:
      return {
        ...state,
        isLoading: false,
        deactive_status: "failed",
        message: action.message,
      };
    case OwnerActionTypes.ADD_LOCATIONS_SUCCESS:
      return {
        ...state,
        addLocationStatus: "success",
      };
    case OwnerActionTypes.ADD_LOCATIONS_DEFAULT:
      return {
        ...state,
        addLocationStatus: "default",
      };
    case OwnerActionTypes.GET_STATISTIC_PROFILE:
      return {
        ...state,
        isLoading: true,
      };
    case OwnerActionTypes.GET_STATISTIC_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profile_statistic: action.payload,
      };
    case OwnerActionTypes.GET_STATISTIC_PROFILE_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.message,
      };
    case OwnerActionTypes.GET_STATISTIC_PAYMENT:
      return {
        ...state,
        isLoading: true,
      };
    case OwnerActionTypes.GET_STATISTIC_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list_payment_verification: action.payload,
      };
    case OwnerActionTypes.GET_STATISTIC_PAYMENT_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.message,
      };
    case OwnerActionTypes.GET_STATISTIC_CANCELLATION:
      return {
        ...state,
        isLoading: true,
      };
    case OwnerActionTypes.GET_STATISTIC_CANCELLATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list_cancellation_request: action.payload,
      };
    case OwnerActionTypes.GET_STATISTIC_CANCELLATION_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.message,
      };
    case OwnerActionTypes.GET_STATISTIC_UPCOMING_CLASS:
      return {
        ...state,
        isLoading: true,
      };
    case OwnerActionTypes.GET_STATISTIC_UPCOMING_CLASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list_upcoming_class: action.payload,
      };
    case OwnerActionTypes.GET_STATISTIC_UPCOMING_CLASS_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.message,
      };
    case OwnerActionTypes.GET_STATISTIC_CLASS_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
      };
    case OwnerActionTypes.GET_STATISTIC_CLASS_IN_PROGRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list_class_in_progress: action.payload,
      };
    case OwnerActionTypes.GET_STATISTIC_CLASS_IN_PROGRESS_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.message,
      };
    case OwnerActionTypes.GET_ONBOARDING_STATUS:
      return {
        ...state,
        isLoading: true,
      };
    case OwnerActionTypes.GET_ONBOARDING_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        onboarding_status: action.payload,
      };
    case OwnerActionTypes.GET_ONBOARDING_STATUS_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.message,
      };
    default:
      return state;
  }
};

export default OwnerReducer;
