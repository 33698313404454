import React from "react";
import moment from "moment";
import Calendar from "react-calendar";

import { FaCalendarAlt } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Modal } from "antd";

const DatePicker = ({
  title,
  currentDate,
  selectedDate,
  show,
  setShow,
  setDate,
  width,
}) => {
  return (
    <div className="">
      <label htmlFor="date" className="custom-form-label">
        {title}
      </label>
      <br />
      <div
        onClick={() => setShow(true)}
        className="pointer custom-hover border-1 border-dark border-bottom py-2"
      >
        <div
          style={{ width: width ?? "auto" }}
          className="d-flex justify-content-between align-items-center"
        >
          {moment(selectedDate).format("DD MMM, YYYY")}
          <FaCalendarAlt />
        </div>
      </div>

      <Modal centered open={show} onCancel={() => setShow(false)}>
        <div className="p-3 text-center">
          <Calendar
            locale="en"
            tileClassName={"rounded-circle"}
            className="border-0"
            prevLabel={<IoIosArrowBack className="fs-5 greyblue" />}
            nextLabel={<IoIosArrowForward className="fs-5 greyblue" />}
            minDate={currentDate}
            onChange={(e) => setDate(e)}
            value={selectedDate}
          />
          <div onClick={() => setShow(false)} className="">
            <p className="pt-3 m-0 pointer custom-hover greyblue">Cancel</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DatePicker;
